import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../../data/SiteConfig';
import styles from './quote-layout.module.scss';
import MainLayout from '../MainLayout/main-layout';


export default function QuoteLayout({ children }) {
  return (
    <MainLayout>
      <Helmet title={`Get Quote | ${config.siteTitle}`} />
      <div className={styles.getQuote}>
        <img className={styles.logo} src={'/logos/logo-light-line-1.svg'} alt="logo" />
        <div className={styles.waveArt} />

        <div className={styles.formContainer}>
          {children}
        </div>
      </div>
    </MainLayout>
  );
}
