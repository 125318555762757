import React from 'react';
import styles from './form.module.scss';

export default function FormField({ children, label, name }) {
  return(
    <div className={styles.formField}>
      <label className={styles.label} htmlFor={name}>{label}</label>
      {children}
    </div>
  );
}
