import React, {useState} from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import styles from './steps.module.scss';
import FormContainer from '../Form/form-container';
import FormHeadline from '../FormHeadline/form-headline';
import FormField from '../Form/form-field';
import CheckBox from '../Form/checkbox';
import TextArea from '../Form/text-area';
import Button from '../Button/button';

export default function DietaryRestrictionsSpecifics({ set, vegetarian, vegan, noGluten, noNuts, noShellfish, noDairy, otherRestrictions }) {
  const intl = useIntl();
  const [_vegetarian, setVegetarian] = useState(vegetarian);
  const [_vegan, setVegan] = useState(vegan);
  const [_noGluten, setGluten] = useState(noGluten);
  const [_noNuts, setNuts] = useState(noNuts);
  const [_noShellfish, setShellfish] = useState(noShellfish);
  const [_noDairy, setDairy] = useState(noDairy);
  const [_other, setOther] = useState(otherRestrictions);

  const specifics = {
    vegetarian: _vegetarian,
    vegan: _vegan,
    noGluten: _noGluten,
    noNuts: _noNuts,
    noShellfish: _noShellfish,
    noDairy: _noDairy,
    otherRestrictions: _other
  };

  const nextStepPath = '/get-quote/contact-info';

  return(
    <FormContainer>
      <FormHeadline>{intl.formatMessage({ id: "quoteDietaryRestrictionsSpecificsHeadline"})}</FormHeadline>
      <div className={styles.dietaryRestrictionsContainer}>
        <CheckBox checked={_vegetarian} handleClick={() => setVegetarian(!_vegetarian)}>
          {intl.formatMessage({ id: "quoteDietaryRestrictionsVegetarian"})}
        </CheckBox>
        <CheckBox checked={_vegan} handleClick={() => setVegan(!_vegan)}>
          {intl.formatMessage({ id: "quoteDietaryRestrictionsVegan"})}
        </CheckBox>
        <CheckBox checked={_noGluten} handleClick={() => setGluten(!_noGluten)}>
          {intl.formatMessage({ id: "quoteDietaryRestrictionsGluten"})}
        </CheckBox>
        <CheckBox checked={_noNuts} handleClick={() => setNuts(!_noNuts)}>
          {intl.formatMessage({ id: "quoteDietaryRestrictionsNuts"})}
        </CheckBox>
        <CheckBox checked={_noShellfish} handleClick={() => setShellfish(!_noShellfish)}>
          {intl.formatMessage({ id: "quoteDietaryRestrictionsShellfish"})}
        </CheckBox>
        <CheckBox checked={_noDairy} handleClick={() => setDairy(!_noDairy)}>
          {intl.formatMessage({ id: "quoteDietaryRestrictionsDairy"})}
        </CheckBox>
      </div>

      <FormField
        label={intl.formatMessage({ id: "quoteDietaryRestrictionsOther"})}
        name={'other'}
      >
        <TextArea
          value={_other}
          handleChange={setOther}
          name={'other'}
          required={false}
        />
      </FormField>

      <div className={styles.buttonContainer}>
        <Link to={nextStepPath}>
          <Button handleClick={() => set({specifics})} disabled={false} elevated={false}>
            {intl.formatMessage({ id: "continueButtonLabel"})}
          </Button>
        </Link>
      </div>
    </FormContainer>
  );
}
