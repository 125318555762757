import React from 'react';
import styles from './button.module.scss';

export default function Button({children, handleClick, elevated, disabled, type}) {
  const elevatedClass = elevated ? styles.elevated : '';
  const t = type || 'button';
  return(
    <button
      type={t}
      className={`${styles.button} ${elevatedClass} ${disabled ? styles.disabled : ''}`}
      onClick={handleClick}
      disabled={disabled}
    >
      <div className={styles.label}>
        {children}
      </div>
    </button>
  );
}
