import React from 'react';
import styles from './form-headline.module.scss';

export default function FormHeadline({ children }) {
  return(
    <div className={styles.formHeadline}>
      {children}
    </div>
  );
}
