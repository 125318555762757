import React from 'react';
import styles from './checkbox.module.scss';

export default function CheckBox({ children, handleClick, checked }) {
  return(
    <button
      type="button"
      className={`${styles.checkbox} ${checked ? styles.checkboxActive : ''}`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}
